import React from "react"
import Img from "gatsby-image"
import Layout from '../components/layout'

// const CommunityPage = ({data}) => (
// 	<Layout>
// 		<div id="content-wrap">
// 			<ul id="news" class="community">
// 				{ data.allMarkdownRemark.edges.map(({ node }) => (
// 					<li className="news-item">
// 					  <h3 className="header">{ node.frontmatter.date } - { node.frontmatter.title }</h3>
// 					  { node.frontmatter.image != null &&
// 			            <span className="news-img"><Img fluid={node.frontmatter.image.childImageSharp.fluid} /></span>
// 			          }
// 					  <div className="content" dangerouslySetInnerHTML={{ __html: node.html }} />
// 					</li>
// 				))}
// 			</ul>
// 		</div>
// 	</Layout>
// )

const CommunityPage = ({data}) => (
  <Layout>
  <div id="content-wrap">
    <ul id="news">
      { data.allNodeSoccercommunity.edges.map(({ node }) => (
        <li className="news-item">
          <h3 className="header">{ node.title }</h3>
          { node.relationships.field_image != null && <span className="news-img"><Img fluid={node.relationships.field_image.localFile.childImageSharp.fluid} /></span> }
          <div className="content" dangerouslySetInnerHTML={{ __html: node.body.value }} />
        </li>
      ))}
    </ul>
  </div>
  </Layout>
)

export default CommunityPage

export const query = graphql`
  query allNodeSoccercommunity {
    allNodeSoccercommunity (filter: {status: {eq: true}}, sort: {fields: field_update_release_date, order: DESC}) {
      edges {
        node {
          id
          title
          body {
            value
            format
            processed
            summary
          }
          relationships {
              field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 792, maxHeight: 387) {  
                    src
                    srcSet
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`